<!-- ########################## <Template> ########################## -->
<template lang="">

        <Header></Header>
        <div class="pageTitle" > 
            <font-awesome-icon icon="caret-right" /> 
            Gestion des Modéles de notifications
        </div>
        
        <div class="pageBodyContainer">
        
            <!--  -->
            
            <!--  -->
            <!-- {{ gridData }} -->
            <div>
                <div class="card">
                    <DataTable ref="dt" :value="gridData"  dataKey="id" 
                        :filters="filters" class="p-datatable-sm"
                        responsiveLayout="scroll">
                        <template #header>
                            <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    <P-Button label="Nouveau Modéle de mail" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="openAddDialog" />
                                </h5>
                                &nbsp;
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                                </span>
                            </div>
                        </template>

                        <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                        <Column :exportable="false">
                            <template #body="slotProps">
                                <P-Button icon="pi pi-pencil" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                                <P-Button icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger" @click="confirmDeleteSelected(slotProps.data)" />
                            </template>
                        </Column>
                        <Column v-for="col in columnsTable" :field="col.field" :header="col.header" :sortable="true" style="min-width:16rem">
                            <template #body="slotProps" v-if="col.field == 'actif'">
                                    <InputSwitch v-model="slotProps.data.actif" @change="onActifChange(slotProps.data)" />
                            </template>
                            <template #body="slotProps" v-else-if="col.type && col.type == 'InputHtml'">
                                <BlockUI :blocked="true">
                                    <div :disabled='true' v-html="slotProps.data[col.field]"></div>
                                </BlockUI>
                            </template>
                        </Column>

                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                        
                    </DataTable>
                </div>

            </div>

            <!-- Delete Dialog -->
            

            <!-- Edit Dialog -->
            <Dialog v-model:visible="showEditDialogFlag" 
                    :style="{width: '1200px'}" 
                    :header="(selectedGridItem.id)?'Edition':'Création d\'un nouveau modéle'" 
                    :modal="true" class="p-fluid"
                    @hide="closeEditDialog"
            >
                <V-Form ref="myForm" @submit="onSubmit" :validation-schema="myValidationSchema" autocomplete="off">

                    <div class="p-field" v-for="fieldObject in fieldsTable">
                        <label :for="fieldObject.field">{{fieldObject.header}}</label>
                        <MultiSelect v-if="fieldObject.type == 'Choice'" v-model="chosenRoles" 
                        :options="fieldObject.ChoiceValues"
                        placeholder="Selectionner les Roles destinataires"
                        class="w-full md:w-20rem" />
                        <Textarea v-else-if="fieldObject.type == 'InputHtml'" v-model="selectedGridItem[fieldObject.field]"    />
                        <div v-else-if="fieldObject.type == 'InputSwitch'">
                            <!-- <br/> -->
                            <InputSwitch v-model="selectedGridItem[fieldObject.field]"    />
                        </div>
                        <V-Field v-else :as="fieldObject.type" :name="fieldObject.field" v-model.trim="selectedGridItem[fieldObject.field]"    />
                        <!-- {{selectedGridItem[fieldObject.field]}} -->
                        <!-- <ErrorMessage :name="fieldObject.field" class="error-feedback" /> -->
                    </div>

                    <!-- <div class="p-field">
                        <label for="ice">ICE</label>
                        <V-Field name="ice"  v-model="selectedGridItem.ice" v-slot="{ field, errors, errorMessage }" >
                            <InputMask name="ice" :modelValue="selectedGridItem.ice" v-model="selectedGridItem.ice"   mask="999999999-9999-99" :autoClear="false" :unmask="true"  />
                        </V-Field> 
                        
                        <ErrorMessage name="ice" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="rc" class="p-mb-3">RC</label>
                        <V-Field as="InputText" name="rc" v-model.trim="selectedGridItem.rc"   />
                        <ErrorMessage name="rc" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="ville" class="p-mb-3">Ville</label>
                        <V-Field as="InputText" name="ville" v-model.trim="selectedGridItem.ville"   />
                        <ErrorMessage name="ville" class="error-feedback" />
                    </div>
                    
                    <div class="p-field">
                        <label for="Roles" class="p-mb-3">Roles disponibles</label>
                        <MultiSelect v-model="chosenRoles" 
                        :options="availableRoles" 
                        optionLabel="titre" 
                        placeholder="Selectionner les Roles par défaut"
                        class="w-full md:w-20rem" />
                        <ErrorMessage name="Roles" class="error-feedback" />
                    </div>
                    

                    <div class="p-field">
                        <label for="activerChoixLimiteCreditMinimum" class="p-mb-3">Activer choix limite de credit minimum</label>
                        <br/><InputSwitch v-model="selectedGridItem.activerChoixLimiteCreditMinimum" />
                        <ErrorMessage name="activerChoixLimiteCreditMinimum" class="error-feedback" />
                    </div>

                    <div class="p-field" v-show="selectedGridItem.activerChoixLimiteCreditMinimum">
                        <label for="LimiteCreditMinimum" class="p-mb-3">Limite de crédit minimum</label>
                        <br/><InputNumber id="locale-us" v-model="selectedGridItem.limiteCreditMinimum" mode="decimal" locale="ar-MA" :minFractionDigits="0"  />
                        <ErrorMessage name="LimiteCreditMinimum" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="actif" class="p-mb-3">Workflow (soumission et validation score)</label>
                        <br/><InputSwitch v-model="selectedGridItem.actif" />
                        <ErrorMessage name="actif" class="error-feedback" />
                    </div>
                    
                    <div class="p-field" v-if="selectedGridItem.id?true:false">
                        <label for="actif" class="p-mb-3">Actif</label>
                        <br/>
                        <InputSwitch id="actif" v-model="selectedGridItem.actif" />
                    </div> -->

                    <!-- <div class="p-field">
                        <label for="loginInforisk" class="p-mb-3">Nom d'utilisateur inforisk</label>
                        <V-Field as="InputText" name="loginInforisk" v-model.trim="selectedGridItem.loginInforisk"   />
                        <ErrorMessage name="loginInforisk" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="passwordInforisk" class="p-mb-3">Mot de passe inforisk</label>
                        <Password name="passwordInforisk" v-model.trim="userPasswordInforisk"   />
                        <ErrorMessage name="passwordInforisk" class="error-feedback" />
                    </div> -->

                <div class="p-d-flex p-jc-end">
                    
                        <button class="btn btn-danger btn-block"  @click="closeEditDialog">
                            <span>Annuler</span>
                        </button> &nbsp;
                        <button  class="btn btn-primary btn-block" @click="saveGridItem">
                            <span>Enregistrer</span>
                        </button>
                    </div>

                </V-Form>
                
            </Dialog>
            
            <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>

        </div>
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Password  from 'primevue/password';
import AuthService from '../0-auth-components/auth.service';
import MultiSelect from 'primevue/multiselect';
import BlockUI from 'primevue/blockui';
import Textarea from 'primevue/textarea';

export default {
    name: "AdminMailTemplates",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Password,
        MultiSelect,
        BlockUI,
        // WebDesigner,
        Textarea
    },
    //--------------------------- Data --------------------------
    computed: {
    },
    data() {
        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                title: yup.string().max(255,"Maximum 255").required("Le titre est obligatoire !"),
                emailSubject: yup.string().max(70,"Le sujet doit comporter maximum 70 caractéres").required("Le sujet est obligatoire !"),
                emailBody: yup.string().required("Le corps du mail est obligatoire !")
        });
        return {
            columnsTable:[
                {field:'title',header:'Titre'},
                {field:'emailSubject',header:'Sujet'},
                {field:'emailBody',header:'Corps de mail',type:'InputHtml'},
                {field:'toRoles',header:'Roles destinataires'},
                {field:'actif',header:'Actif'}
            ],
            fieldsTable:[
                {field:'title',header:'Titre',type:'InputText'},
                {field:'emailSubject',header:'Sujet',type:'InputText'},
                {field:'emailBody',header:'Corps de mail',type:'InputHtml'},
                {field:'toRoles',header:'Roles destinataires',type:'Choice',ChoiceValues:['validationQualitative','validationFinanciere','scoringQualitative','scoringFinanciere','scoringPublication']},
                {field:'actif',header:'Actif',type:'InputSwitch'}
            ],
            gridData: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0, actid:true},
            selectedGridInitialState: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            userPasswordInforisk:"",
            chosenRoles:[], 
            availableRoles:[]
        } // end result
    }, //end data()
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- mounted()");

        this.loadGridData();

    }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        onSubmit(values, actions) {
            this.log("onSubmit");
            this.log(values);

            this.saveGridItem();
        },
        saveGridItem()
        {
            this.log("saveGridItem");
            this.log(this.selectedGridItem);
            
            // this.selectedGridItem.actif=this.selectedGridItem.actif?1:0

            
            this.selectedGridItem['toRoles'] = this.chosenRoles.map(function(role){
                            return role
                        }).join(",")

            if(this.selectedGridItem.id > 0)
            {
                CRUDService.update(this.selectedGridItem,'mailNotificationTemplates').then(
                    (response) => {
                        this.log(response.data);
                        this.toastSuccess("mailNotificationTemplates "+ this.selectedGridItem.id +" modifié avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;
                    },
                    (error) => {
                        this.toastError("Echec sauvegarde de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
            else
            {
                CRUDService.create(this.selectedGridItem,'mailNotificationTemplates').then(
                    (response) => {
                        this.log(response.data);
                        this.gridData.push(response.data);
                        this.toastSuccess("mailNotificationTemplates "+ this.selectedGridItem.id +" créé avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;
                    },
                    (error) => {
                        this.toastError("Echec création de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
        },
        openAddDialog() {
            this.openEditDialog({id:0, actif:true, title:''});
        },
        openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;

            this.chosenRoles = this.selectedGridItem['toRoles'].split(',')
            
            this.selectedGridItem.actif=this.selectedGridItem.actif?true:false

            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            
            for (let index = 0; index < this.gridData.length; index++) {
                var element = this.gridData[index];
                if(element.id == this.selectedGridItem.id)
                {
                    this.log(this.selectedGridItemInitialState);
                    this.gridData[index] = this.selectedGridItemInitialState;
                    break;
                }
            }

            this.showEditDialogFlag = false;
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le mail '+this.selectedGridItem.title+'?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        deleteGridItem()
        {
            this.log("deleteGridItem");
            CRUDService.delete(this.selectedGridItem,'mailNotificationTemplates').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                    this.toastSuccess("mailNotificationTemplates "+ this.selectedGridItem.title +" supprimé avec succès");
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        loadGridData: function()
        {
            this.log("------loadGridData");
            CRUDService.list('mailNotificationTemplates').then( //actif
                (response) => {
                    this.log(response.data);
                    this.gridData = response.data;
                    
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        onActifChange(selectedItem)
        {
            this.selectedGridItem = selectedItem;
            this.saveGridItem();
        },

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.table-header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}


</style>